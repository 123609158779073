import { TransferReason } from '@bits-app/voggtpit-shared';
import { z } from 'zod';

import { AllowedFeatureForMassImport } from './type';

export const MassImportResolver: Record<
  AllowedFeatureForMassImport,
  {
    commitPath: string;
    backendValidationPath?: string;
    validate: z.ZodSchema;
  }
> = {
  updateFee: {
    commitPath: 'database-explorer/customer/update-mass-fee',
    validate: z.array(
      z.object({
        userid: z.coerce.number().gt(1).int(),
        percentagefee: z.coerce.number(),
        fixedfee: z.coerce.number().gte(0).int(),
        includeshippingfeesforcommissionbydefault: z.coerce.boolean(),
      }),
    ),
  },
  insertSellerCompany: {
    commitPath: 'database-explorer/seller-company/bulk/import',
    backendValidationPath: 'database-explorer/seller-company/bulk/check',
    validate: z.array(
      z.object({
        userId: z.coerce.number(),
        legalName: z.string(),
        address: z.string(),
        city: z.string(),
        zipcode: z.string(),
        state: z.string().optional(),
        country: z.string(),
        phone: z.string(),
        vatId: z.string().optional(),
        isValid: z.coerce.boolean().optional(),
        vatIdValidSince: z.string().optional(),
        registrationNumber: z.string(),
        bankAccountNumber: z.string().optional(),
        bic: z.string().optional(),
        bankName: z.string().optional(),
        routingNumber: z.string().optional(),
        taxId: z.string().optional(),
        taxIdRegistrar: z.string().optional(),
        lucidId: z.string().optional(),
      }),
    ),
  },
  transferFund: {
    commitPath: 'payment/transfer/bulk',
    backendValidationPath: 'payment/available-for-transfer',
    validate: z.array(
      z.object({
        userId: z.coerce.number(),
        amount: z.coerce.number(),
        description: z.string().optional(),
        currency: z.enum(['eur', 'gbp']),
        transferReason: z.nativeEnum(TransferReason),
        transferType: z.enum(['bank-account', 'stripe-account']),
        orderId: z.coerce.number().optional(),
        relatedMonth: z.string().optional(),
        sellerId: z.coerce.number().optional(),
        shipmentId: z.coerce.string().optional(),
        showId: z.coerce.number().optional(),
      }),
    ),
  },
  updateBadge: {
    commitPath: 'database-explorer/customer/update-mass-badge',
    validate: z.array(
      z.object({
        userId: z.coerce.number(),
        badgeId: z.coerce.number(),
      }),
    ),
  },
  updateStripeNegativeBalanceDebits: {
    commitPath: 'database-explorer/customer/update-negative-balance-debits',
    validate: z.array(
      z.object({
        stripeAccountId: z.string().regex(/^acct_1[A-z0-9]{15}$/, {
          message: 'Invalid Stripe account ID format',
        }),
        debitNegativeBalances: z
          .string()
          .refine((val) => ['true', 'false'].includes(val.toLowerCase().trim()), {
            message: "debitNegativeBalances must be either 'true' or 'false'",
          }),
      }),
    ),
  },
};
