import {
  Show,
  CommercialEvent,
  User,
  Shipment,
  ShippingAddress,
  FeaturedShowApplicationType,
} from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { authenticatedInstance } from '@/axios/axios.instance';
import { ListCustom } from '@/components/elements/DataExplorer/ListCustom';
import { CellDefinition, DataListType } from '@/components/elements/DataList/datalist-type';
import { useOwnUser } from '@/context/own-user.context';
import { QUERIES_KEYS } from '@/queries';

type availablePath =
  | 'commercial-event-applications'
  | 'commercial-event-related-products'
  | 'commercial-event-related-shows'
  | 'featured-show'
  | 'notifications-center'
  | 'orders-from-shipment'
  | 'product'
  | 'products-from-shipment'
  | 'shipment-rating'
  | 'shipments-from-shipping-address'
  | 'shipments-from-user'
  | 'shipping-address'
  | 'show';

type availableFilter = {
  applicationType?: FeaturedShowApplicationType;
  commercialEventId?: CommercialEvent['id'];
  customerId?: User['id'];
  isDone?: boolean;
  sellerId?: User['id'];
  shipmentId?: Shipment['id'];
  shippingAddressId?: ShippingAddress['id'];
  showId?: Show['id'];
  status?: 'pending' | 'approved';
  userId?: User['id'];
  withGmv?: boolean;
};

type ListWithExportProps<T extends { id: string | number }> = {
  filters: availableFilter;
  cellDefinition: CellDefinition<T>[];
  defaultHiddenColumns?: DataListType<T>['defaultHiddenColumns'];
  defaultSortBy?: DataListType<T>['defaultSortBy'];
  enableRowSelection?: DataListType<T>['enableRowSelection'];
  renderRowActions?: DataListType<T>['renderRowActions'];
  dataName: availablePath;
  customSelectedRowsAction?: (params: { original: T }[]) => JSX.Element;
};

export const ListWithExport = <T extends { id: string }>({
  renderRowActions,
  filters,
  dataName,
  cellDefinition,
  defaultHiddenColumns,
  defaultSortBy,
  customSelectedRowsAction,
  enableRowSelection,
}: ListWithExportProps<T>) => {
  const { t } = useTranslation();

  const { hasPermissions } = useOwnUser();

  const ownUserCanSelectRows = hasPermissions(
    PERMISSIONS.OWN_USER_INTERFACE.READ_OWN_USER_INTERFACE_SELECT_MULTIPLE_LINES,
  );

  const { data, refetch } = useQuery<T[]>(getQueryKeys(dataName, filters), () =>
    getQueryFunction(dataName, filters),
  );

  const refetchData = () => refetch();

  return (
    <ListCustom
      cellDefinition={cellDefinition}
      defaultHiddenColumns={defaultHiddenColumns}
      enableRowSelection={enableRowSelection ?? ownUserCanSelectRows}
      enableFilters
      defaultSortBy={defaultSortBy}
      results={data ?? []}
      renderRowActions={renderRowActions}
      allowMRTExport
      enableSorting
      customSelectedRowsAction={customSelectedRowsAction}
    >
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <IconButton title={t('action.refresh')} onClick={refetchData}>
          <RefreshIcon />
        </IconButton>
      </Box>
    </ListCustom>
  );
};

const resolvePathFromDataName = (dataName: availablePath) => {
  switch (dataName) {
    case 'commercial-event-applications':
      return '/database-explorer/commercial-event/applications';
    case 'commercial-event-related-products':
      return '/database-explorer/commercial-event/related-products';
    case 'commercial-event-related-shows':
      return '/database-explorer/commercial-event/related-shows';
    case 'featured-show':
      return '/database-explorer/featured-show-application/search';
    case 'notifications-center':
      return '/notifications-center';
    case 'orders-from-shipment':
      return '/database-explorer/shipment/orders-from-shipment';
    case 'product':
      return '/database-explorer/product';
    case 'products-from-shipment':
      return '/database-explorer/shipment/products-from-shipment';
    case 'shipments-from-shipping-address':
      return '/database-explorer/shipment/shipments-from-shipping-address';
    case 'shipments-from-user':
      return '/database-explorer/shipment/shipments-from-user';
    case 'shipment-rating':
      return '/database-explorer/shipment-rating';
    case 'shipping-address':
      return '/database-explorer/shipping-address';
    case 'show':
      return '/database-explorer/show';
    default:
      return '';
  }
};

const getQueryFunction = async (dataName: availablePath, filters: availableFilter) => {
  const response = await authenticatedInstance.get(resolvePathFromDataName(dataName), {
    params: filters,
  });

  return response.data;
};

const getQueryKeys = (dataName: availablePath, filters: availableFilter) => {
  switch (dataName) {
    case 'product':
      return QUERIES_KEYS.productList(filters?.sellerId);
    case 'show':
      return QUERIES_KEYS.showList(filters?.userId);
    case 'orders-from-shipment':
      return QUERIES_KEYS.ordersFromShipment(filters?.shipmentId);
    case 'products-from-shipment':
      return QUERIES_KEYS.productsFromShipment(filters?.shipmentId);
    case 'shipments-from-user':
      return QUERIES_KEYS.shipmentsFromUser(filters?.sellerId);
    case 'shipments-from-shipping-address':
      return QUERIES_KEYS.shipmentsFromShippingAddress(filters?.shippingAddressId);
    case 'commercial-event-related-shows':
      return QUERIES_KEYS.commercialEventRelatedShows(filters?.commercialEventId);
    case 'commercial-event-related-products':
      return QUERIES_KEYS.commercialEventRelatedProducts(filters?.commercialEventId);
    case 'shipping-address':
      return QUERIES_KEYS.userShippingAddresses(filters?.userId);
    case 'featured-show':
      return QUERIES_KEYS.featuredShowApplicationList(
        filters?.applicationType,
        filters?.status,
        filters?.isDone,
      );
    case 'shipment-rating':
      return QUERIES_KEYS.shipmentRatingList(filters?.sellerId);
    case 'notifications-center':
      return QUERIES_KEYS.notificationList(filters?.customerId);
    case 'commercial-event-applications':
      return QUERIES_KEYS.commercialEventApplicationList(filters?.status, filters?.isDone);
  }
};
