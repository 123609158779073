import type { Product } from '@bits-app/bits-server-data';
import { Order } from '@bits-app/bits-server-data';
import { PERMISSIONS } from '@bits-app/voggtpit-shared';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ListWithSearch } from '@/components/elements/DataExplorer/ListWithSearch';
import { Dropdown } from '@/components/elements/Dropdown';
import { ForestAdminLink } from '@/components/elements/ForestAdminLink';
import { PanelDetailImage } from '@/components/elements/PanelDetailImage';
import { SoftDeletedBanner } from '@/components/elements/banner/SoftDeletedBanner';
import { PanelDetails } from '@/components/layouts/PanelDetails';
import { useOwnUser } from '@/context/own-user.context';
import { useIdParam } from '@/hooks/use-id-params';
import { useNavigateInDetailTab } from '@/hooks/use-navigation-detail-in-tab';
import { EditButton } from '@/voggt-database-explorer/components/EditButton';
import { ItemLayout, ItemTab } from '@/voggt-database-explorer/layouts/ItemLayout';
import { User } from '@/voggt-database-explorer/users/users';

import { AddToCommercialEventModal } from '../../../commercial-event/components/AddToCommercialEvent.modal';
import { useAddToCommercialEvent } from '../../../commercial-event/hooks/use-add-to-commercial-event';

import {
  CellOrderDefinition,
  panelDetailsProduct,
  panelDetailsUser,
  panelProductAttributes,
  useDetailsView,
} from './useDetailsView';

export const ProductDetail = () => {
  const productId = useIdParam();
  const { t } = useTranslation();
  const { hasPermissions } = useOwnUser();

  const isReadOnly = !hasPermissions(PERMISSIONS.DATABASE_EXPLORER.WRITE_DATABASE_EXPLORER_PRODUCT);

  const handleOnTabChange = useNavigateInDetailTab(`/product/${productId}`);

  const { product, loading, refreshData } = useDetailsView(productId);

  const tabs: ItemTab[] = [
    {
      label: 'Details',
      path: '/',
      Element: (
        <ProductDetailsComponent
          key="/"
          isReadOnly={isReadOnly}
          product={product}
          loading={loading}
        />
      ),
    },
  ];

  if (!productId) return null;

  return (
    <ItemLayout<Product>
      id={productId}
      tabs={tabs}
      currentTab={'/'}
      title={product?.name}
      description={product.description ?? undefined}
      imageURL={product?.images?.[0]?.url}
      onTabChange={handleOnTabChange}
      editionDescriptor={
        isReadOnly
          ? undefined
          : {
              goBackName: t('databaseExplorer.editionFormPath.products', { productId }),
              resourceId: productId,
              resourceType: 'Product',
              onSuccess: refreshData,
            }
      }
    />
  );
};

type ProductDetailsComponentProps = {
  isReadOnly: boolean;
  product: Product;
  loading: boolean;
};

const ProductDetailsComponent = ({
  isReadOnly,
  product,
  loading,
}: ProductDetailsComponentProps) => {
  const productId = useIdParam();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const addProductToCommercialEventHook = useAddToCommercialEvent(+productId, 'products');

  const itemsMenuList = [
    {
      key: 'databaseExplorer.commercialEvent.add.title',
      onClick: addProductToCommercialEventHook.toggleModalStatus,
      forbidden: false,
    },
  ];

  return (
    <>
      {product?.deletedAt && <SoftDeletedBanner />}
      <div className="flex justify-end items-center gap-2 mb-2">
        {!isReadOnly && <EditButton to={`/product/${productId}/edit`} />}

        <ForestAdminLink entity="product" entityId={productId} />
        <Dropdown
          title="Actions"
          options={itemsMenuList.map((item) => ({
            onClick: () => item.onClick && item.onClick(),
            forbidden: item.forbidden,
            label: t(item.key),
          }))}
        />
      </div>

      <div className="flex flex-col gap-4 lg:flex-row mb-2">
        <div className="flex flex-1 flex-col gap-4">
          <PanelDetails<Product>
            title={t('databaseExplorer.panel.main')}
            informations={panelDetailsProduct}
            data={product}
            loading={loading}
          />
        </div>

        <div className="flex flex-1 flex-col gap-4">
          <PanelDetailImage
            alt={product.name}
            images={product?.images?.map((image) => image.url) ?? []}
          />

          <PanelDetails<User>
            title={t('databaseExplorer.panel.user')}
            informations={panelDetailsUser}
            data={product?.seller}
            loading={loading}
            seeDetails={(seller) => navigate(`/user/${seller.id}`)}
          />

          <PanelDetails<Product>
            title={'Product attributes'}
            informations={panelProductAttributes}
            data={product}
            loading={loading}
          />
        </div>
      </div>

      <div>
        <ListWithSearch<Order & { id: string; seller: string; customer: string; product: string }>
          title="Orders"
          disableAutoFocus
          cellDefinition={CellOrderDefinition}
          defaultFilters={[{ field: 'productId', values: [productId] }]}
          typesenseHookParams={{
            typesenseParams: { per_page: 20, query_by: ['seller', 'customer', 'product'] },
            typesenseIndex: 'order',
          }}
        />
      </div>

      {addProductToCommercialEventHook.modalIsOpen && (
        <AddToCommercialEventModal
          onCancel={addProductToCommercialEventHook.toggleModalStatus}
          onConfirm={addProductToCommercialEventHook.submitUpdate}
        />
      )}
    </>
  );
};
